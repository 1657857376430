import Beef from './Beef.png';
import BottleCanBeer from './Bottle&CanBeer.png';
import Butter from './Butter.png';
import CannedFood from './CannedFood.png';
import Cheese from './Cheese.png';
import Chicken from './Chicken.png';
import Coffee from './Coffee.png';
import CookingPowder from './CookingPowder.png';
import Cream from './Cream.png';
import Cutlery from './Cutlery.png';
import Detergent from './Detergent.png';
import DriedAgriculturalProduct from './DriedAgriculturalProduct.png';
import Egg from './Egg.png';
import Fish from './Fish.png';
import Flour from './Flour.png';
import FreshJuice from './FreshJuice.png';
import Fruit from './Fruit.png';
import Honey from './Honey.png';
import JapaneseSakeShochu from './JapaneseSake&Shochu.png';
import Kitchenware from './Kitchenware.png';
import Milk from './Milk.png';
import Mushroom from './Mushroom.png';
import Oil from './Oil.png';
import PastaNoodles from './Pasta&Noodles.png';
import Pork from './Pork.png';
import ProcessedSeafood from './ProcessedSeafood.png';
import ProcessedVegetables from './ProcessedVegetables.png';
import Rice from './Rice.png';
import Sauce from './Sauce.png';
import SausageHam from './Sausage&Ham.png';
import Seasoning from './Seasoning.png';
import ShelClam from './Shel&Clam.png';
import Shrimp from './Shrimp.png';
import SoftDrink from './SoftDrink.png';
import Squid from './Squid.png';
import Syrup from './Syrup.png';
import Tea from './Tea.png';
import TissueTissueDispenser from './Tissue&TissueDispenser.png';
import Vegetable from './Vegetable.png';
import Vinegar from './Vinegar.png';
import Water from './Water.png';
import Yogurt from './Yogurt.png';
import DuckGoose from './DuckGoose.png';
import Lamb from './Lamb.png';
import PlantBased from './PlantBased.png';
import Crab from './Crab.png';
import Octopus from './Octopus.png';
import Puree from './Puree.png';
import IceCream from './IceCream.png';
import Bread from './Bread.png';
import GrainsNuts from './GrainsNuts.png';
import BakersConfectionery from './BakersConfectionery.png';
import Sugarconfectionery from './Sugarconfectionery.png';
import Jam from './Jam.png';
import Snack from './Snack.png';
import WhiteWine from './WhiteWine.png';
import RedWine from './RedWine.png';
import RoseWine from './RoseWine.png';
import SparklingWine from './SparklingWine.png';
import Liquor from './Liquor.png';
import Whisky from './Whisky.png';
import DraftBeer from './DraftBeer.png';
import Spirits from './Spirits.png';
import Dishes from './Dishes.png';
import GlassBottle from './GlassBottle.png';
import Apparel from './Apparel.png';
import Laundry from './Laundry.png';
import TakeawayGoods from './TakeawayGoods.png';
import CleaningProduct from './CleaningProduct.png';
import TissuePaper from './TissuePaper.png';
import HairCare from './HairCare.png';
import BodyWash from './BodyWash.png';
import OralHygiene from './OralHygiene.png';
import Sanitary from './Sanitary.png';

export default {
  Beef: Beef,
  BottleCanBeer: BottleCanBeer,
  Butter: Butter,
  CannedFood: CannedFood,
  Cheese: Cheese,
  Chicken: Chicken,
  Coffee: Coffee,
  CookingPowder: CookingPowder,
  Cream: Cream,
  Cutlery: Cutlery,
  Detergent: Detergent,
  DriedAgriculturalProduct: DriedAgriculturalProduct,
  Egg: Egg,
  Fish: Fish,
  Flour: Flour,
  FreshJuice: FreshJuice,
  Fruit: Fruit,
  Honey: Honey,
  JapaneseSakeShochu: JapaneseSakeShochu,
  Kitchenware: Kitchenware,
  Milk: Milk,
  Mushroom: Mushroom,
  Oil: Oil,
  PastaNoodles: PastaNoodles,
  Pork: Pork,
  ProcessedSeafood: ProcessedSeafood,
  ProcessedVegetables: ProcessedVegetables,
  Rice: Rice,
  Sauce: Sauce,
  SausageHam: SausageHam,
  Seasoning: Seasoning,
  ShelClam: ShelClam,
  Shrimp: Shrimp,
  SoftDrink: SoftDrink,
  Squid: Squid,
  Syrup: Syrup,
  Tea: Tea,
  TissueTissueDispenser: TissueTissueDispenser,
  Vegetable: Vegetable,
  Vinegar: Vinegar,
  Water: Water,
  Yogurt: Yogurt,
  DuckGoose: DuckGoose,
  Lamb: Lamb,
  'Plant-based': PlantBased,
  Crab: Crab,
  Octopus: Octopus,
  Puree: Puree,
  IceCream: IceCream,
  Bread: Bread,
  GrainsNuts: GrainsNuts,
  "Bakers'confectionery": BakersConfectionery,
  Sugarconfectionery: Sugarconfectionery,
  Jam: Jam,
  Snack: Snack,
  WhiteWine: WhiteWine,
  RedWine: RedWine,
  RoseWine: RoseWine,
  SparklingWine: SparklingWine,
  Liquor: Liquor,
  Whisky: Whisky,
  DraftBeer: DraftBeer,
  Spirits: Spirits,
  Dishes: Dishes,
  GlassBottle: GlassBottle,
  Apparel: Apparel,
  TakeawayGoods: TakeawayGoods,
  Laundry: Laundry,
  CleaningProduct: CleaningProduct,
  TissuePaper: TissuePaper,
  HairCare: HairCare,
  BodyWash: BodyWash,
  OralHygiene: OralHygiene,
  Sanitary: Sanitary,
};
